import { routeMap } from '/routeMap'

import { ContainerLg } from '/components/buildingBlocks/Container'
import { TextPrimaryMd } from '/components/buildingBlocks/Text'
import { ButtonLinkSecondary } from '/components/buildingBlocks/Button'
import { animated, useInView, useTrail } from 'react-spring'

import zoomies from '/images/corporate/zoomies/zoomies.png'
import zoomies400w from '/images/corporate/zoomies/zoomies-400w.avif'
import zoomies600w from '/images/corporate/zoomies/zoomies-600w.avif'
import zoomies800w from '/images/corporate/zoomies/zoomies-800w.avif'
import zoomies1000w from '/images/corporate/zoomies/zoomies-1000w.avif'
import zoomies1200w from '/images/corporate/zoomies/zoomies-1200w.avif'
import zoomies1600w from '/images/corporate/zoomies/zoomies-1600w.avif'

import styles from './Figures.css'

const figures = [
  { largeFigure: '12.000', text: 'professionals working for our clients' },
  { largeFigure: '€1.01', text: 'billion euros turnover in 2023' },
  { largeFigure: '300+', text: 'employees' },
  { largeFigure: '20.3%', text: 'increase in turnover 2023' },
]

export function Figures({ layoutClassName }) {
  const [inViewRef, inView] = useInView({ once: true, rootMargin: '-35% 0%' })
  const [springs] = useTrail(figures.length, () => ({
    opacity: inView ? 1 : 0.1,
    trail: 35
  }), [inView])

  return (
    <div ref={inViewRef} className={styles.component}>
      <ContainerLg {...{ layoutClassName }}>
        <div className={styles.figuresInner}>
          {figures.map((f, i) => (
            <Figure key={i} animation={springs[i]} {...f} />
          ))}
          <ButtonLinkSecondary href={routeMap.investors()} layoutClassName={styles.buttonLayout}>
            More for investors
          </ButtonLinkSecondary>
        </div>
      </ContainerLg>
    </div>
  )
}

function Figure({ largeFigure, text, animation = undefined }) {
  return (
    <animated.div className={styles.componentFigure} style={animation} data-style-context='dark'>
      <Digit digit={largeFigure} />
      <TextPrimaryMd>
        {text}
      </TextPrimaryMd>
    </animated.div>
  )
}

function Digit({ digit }) {
  return (
    <h3 className={styles.componentDigit}>
      {digit}
    </h3>
  )
}

const sizes = {
  400: zoomies400w,
  600: zoomies600w,
  800: zoomies800w,
  1000: zoomies1000w,
  1200: zoomies1200w,
  1600: zoomies1600w
}

function BackgroundImage({ layoutClassName }) {
  return (
    <img
      alt=''
      role='presentation'
      className={cx(styles.componentBackgroundImage, layoutClassName)}
      src={zoomies}
      srcSet={Object.entries(sizes).map(([k, v]) => `${v} ${k}w`).join(', ')}
      sizes={Object.keys(sizes).map(x => `(max-width: ${x}px) ${x}w`).join(', ')}
    />
  )
}
